h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

button {
  padding: 0;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  --toastify-toast-width: 624px;
  --toastify-toast-min-height: 62px;
  --toastify-color-progress-success: #c7e1dc;
  --toastify-color-progress-info: #907ba6;
  --toastify-color-progress-error: rgba(255, 255, 255, 0.6);
}

.ReactCollapse--collapse {
  transition: height 275ms ease-in-out;
}
